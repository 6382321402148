import { useForm, Controller } from 'react-hook-form';
import ComicUpload from '@/routes/dashboard/components/ComicUpload';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { NewComicFormData } from '../../../forms/newComicFormData';
import { ExpandableSeriesSection } from './ExpandableSeriesSection';
import { useStore } from 'zustand';
import comicUploadStore from '@/stores/comicUploadStore';
import { useSearchParams } from 'react-router-dom';
import { useSeries } from '@/hooks/series/useSeries';
import { useEffect } from 'react';
import { useComicCreateMutation } from './useComicCreate';

const NewComic = () => {
  const [searchParams] = useSearchParams();
  const seriesId = searchParams.get('series');
  const { series } = useSeries(seriesId ?? undefined);

  // load user uploaded images from store as initial values
  const { userUploadedImages, setUserUploadedImages } = useStore(
    comicUploadStore,
    (state) => state
  );

  const { control, handleSubmit, formState, watch, setValue } =
    useForm<NewComicFormData>({
      defaultValues: {
        chapterNumber: undefined,
        chapterTitle: '',
        files: userUploadedImages,
        series: undefined,
        newSeries: undefined,
      },
    });

  useEffect(() => {
    if (series) {
      setValue('series', series);
    }
  }, [series, setValue]);

  const createComicMutation = useComicCreateMutation('newComicToast');

  const onSubmit = (data: NewComicFormData) => {
    createComicMutation.mutate(data);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="mt-12 sm:mt-14 mb-6 container mx-auto px-3 sm:px-8 flex flex-col gap-8 sm:gap-10"
    >
      <h1 className="text-3xl text-slate-600 font-semibold text-center sm:text-left">
        Upload New Comic
      </h1>

      <div className="p-4 sm:p-8 bg-slate-50 rounded-lg flex flex-col gap-6 shadow-sm border border-slate-200">
        <div className="flex flex-col">
          <h2 className="text-xl text-slate-600 font-semibold">Step 1</h2>
          <h3 className="text-sm text-slate-400 font-bold">
            Basic Information
          </h3>
        </div>
        <div className="flex flex-col sm:flex-row gap-4">
          <Controller
            name="chapterNumber"
            control={control}
            rules={{ required: 'Chapter number is required' }}
            render={({ field }) => (
              <Input
                {...field}
                autoComplete="off"
                placeholder="*Chapter Number"
                className="sm:basis-[240px]"
              />
            )}
          />
          <Controller
            name="chapterTitle"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Chapter Title (optional)"
                autoComplete="off"
              />
            )}
          />
        </div>
        {formState.errors.chapterNumber && (
          <p className="text-red-500">
            {formState.errors.chapterNumber.message}
          </p>
        )}
      </div>

      <div className="p-4 sm:p-8 bg-slate-50 rounded-lg flex flex-col gap-6 shadow-sm border border-slate-200">
        <div className="flex flex-col">
          <h2 className="text-xl text-slate-600 font-semibold">Step 2</h2>
          <h3 className="text-sm text-slate-400 font-bold">Upload Images</h3>
        </div>
        <Controller
          name="files"
          control={control}
          rules={{ required: 'At least one image is required' }}
          render={({ field }) => (
            <ComicUpload
              onChange={(f) => {
                setUserUploadedImages(f ?? []);
                field.onChange(f ?? []);
              }}
              value={field.value}
              className={`${
                field.value.length === 0
                  ? 'h-[200px] sm:h-[300px]'
                  : 'min-h-[200px] sm:min-h-[200px] max-h-[600px] sm:max-h-[600px]'
              } shadow-none p-0`}
            />
          )}
        />
        {formState.errors.files && (
          <p className="text-red-500">{formState.errors.files.message}</p>
        )}
      </div>

      <ExpandableSeriesSection
        control={control}
        errors={formState.errors}
        watch={watch}
      />

      <div className="ml-auto">
        <Button type="submit" size={'lg'} variant={'default'}>
          Upload
        </Button>
      </div>
    </form>
  );
};

export default NewComic;

import { cn, fileSize } from '@/lib/utils';
import { faArrowUp, faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

const traverseDirectory = async (
  entry: FileSystemDirectoryEntry
): Promise<File[]> => {
  const files: File[] = [];
  const reader = entry.createReader();

  // Function to read entries recursively/iteratively
  const readEntriesBatch = (): Promise<void> => {
    return new Promise((resolveBatch, rejectBatch) => {
      reader.readEntries(async (entries) => {
        if (!entries.length) {
          // No more entries in this directory
          resolveBatch();
          return;
        }

        // Create promises for all entries in this batch
        const promises: Promise<File | File[]>[] = [];
        for (const ent of entries) {
          if (ent.isFile) {
            const filePromise = new Promise<File>((resolveFile, rejectFile) => {
              // Explicitly cast to FileSystemFileEntry
              (ent as FileSystemFileEntry).file(resolveFile, rejectFile);
            });
            promises.push(filePromise);
          } else if (ent.isDirectory) {
            // Recursively traverse subdirectory
            const dirPromise = traverseDirectory(
              ent as FileSystemDirectoryEntry
            );
            promises.push(dirPromise);
          }
        }

        try {
          // Wait for all file reads and directory traversals in this batch
          const results = await Promise.all(promises);

          // Process results: flatten arrays from subdirectories
          for (const result of results) {
            if (Array.isArray(result)) {
              files.push(...result); // Add files from subdirectories
            } else if (result instanceof File) {
              files.push(result); // Add file from current directory
            }
          }

          // Read the next batch
          await readEntriesBatch(); // Wait for the next batch to complete
          resolveBatch(); // Current batch and subsequent batches are done
        } catch (error) {
          rejectBatch(error); // Propagate errors
        }
      }, rejectBatch); // Handle errors during readEntries itself
    });
  };

  // Start reading the first batch and wait for all batches to complete
  await readEntriesBatch();
  return files; // Return the accumulated files
};

interface ComicUploadProps {
  onChange: (value: File[]) => void;
  height?: number;
  width?: number;
  value?: File[];
  className?: string;
}

const ComicUpload = ({ onChange, value, className }: ComicUploadProps) => {
  const [isHovering, setIsHovering] = useState(false);

  const sortFilesByName = (files: File[]): File[] => {
    return [...files].sort((a, b) =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
      })
    );
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsHovering(true);
  };

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsHovering(false);
  };

  const onDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsHovering(false);
    const droppedFiles: File[] = [];

    const items = Array.from(e.dataTransfer.items);
    for (const item of items) {
      const entry = item.webkitGetAsEntry() as FileSystemEntry | null;
      if (entry) {
        if (entry.isFile) {
          const file = item.getAsFile();
          if (file) {
            droppedFiles.push(file);
          }
        } else if (entry.isDirectory) {
          const directoryFiles = await traverseDirectory(
            entry as FileSystemDirectoryEntry
          );
          droppedFiles.push(...directoryFiles);
        }
      } else if (e.dataTransfer.files) {
        // Fallback for browsers that don't support webkitGetAsEntry for directories
        const newFiles = Array.from(e.dataTransfer.files);
        droppedFiles.push(...newFiles);
        break; // Assuming if we get files here, we don't need to process items further for directory handling
      }
    }

    if (droppedFiles.length > 0) {
      const allFiles = value ? [...value, ...droppedFiles] : [...droppedFiles];
      const sortedFiles = sortFilesByName(allFiles);
      onChange(sortedFiles);
    }
  };

  const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      // Combine the newly selected files with the existing files
      const newFiles = Array.from(e.target.files);
      const allFiles = value ? [...value, ...newFiles] : [...newFiles];
      const sortedFiles = sortFilesByName(allFiles);
      onChange(sortedFiles);
    }
  };

  const removeFile = (indexToRemove: number) => {
    if (!value) return;
    const newValue = value.filter((_, index) => index !== indexToRemove);
    onChange(newValue);
  };

  const isEmpty = !value || value.length === 0;

  return (
    <>
      <section
        className={cn(
          `flex flex-col justify-center items-center rounded-md shadow-sm bg-slate-50 ${
            isEmpty ? 'p-2' : ''
          }`,
          className
        )}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        {!isEmpty && (
          <div className="flex items-center mb-4 justify-between w-full rounded-t-lg">
            <button
              type="button"
              className="text-slate-600 font-medium hover:text-slate-700 basis-20 flex justify-start flex-1"
              onClick={() => onChange([])}
            >
              Cancel
            </button>
            <span className="font-medium text-slate-600">
              {value.length} file{value.length > 1 ? 's' : ''} selected
            </span>
            <label
              htmlFor="file-input"
              className="cursor-pointer items-center gap-1 text-slate-600 hover:text-slate-700 font-medium flex basis-20 justify-end flex-1"
            >
              <FontAwesomeIcon
                icon={faPlus}
                className="text-sm translate-y-[1px]"
              ></FontAwesomeIcon>
              Add more
            </label>
          </div>
        )}
        <div
          className={`p-4 flex-1 flex w-full rounded-md border-2 border-dashed overflow-y-auto ${
            isEmpty
              ? ' justify-center items-center pointer-events-none'
              : 'border-transparent'
          } ${isEmpty && isHovering ? 'border-blue-500' : ''}
          ${isEmpty && !isHovering ? 'border-slate-300' : ''}
          `}
        >
          <input
            type="file"
            multiple
            accept="image/jpeg,image/png,image/webp"
            className="hidden"
            onChange={onFileInputChange}
            id="file-input"
          />
          {isEmpty ? (
            <div className="cursor-pointer text-xl w-full h-full flex items-center justify-center">
              {isHovering ? (
                <div className="flex flex-col gap-2 justify-center items-center pointer-events-none">
                  <div className="border-2 border-blue-500 rounded-full flex items-center justify-center h-16 w-16">
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      size="2xl"
                      className="text-blue-500"
                    />
                  </div>
                  Drop your files here
                </div>
              ) : (
                <span className="text-center">
                  Drop files here, or{' '}
                  <label
                    htmlFor="file-input"
                    className="cursor-pointer text-blue-500 pointer-events-auto hover:text-blue-600"
                  >
                    click here
                  </label>{' '}
                  to browse
                </span>
              )}
            </div>
          ) : (
            <UploadedImageGrid images={value} onRemove={removeFile} />
          )}
        </div>
      </section>
    </>
  );
};

const UploadedImageGrid = ({
  images,
  onRemove,
}: {
  images?: File[];
  onRemove: (index: number) => void;
}) => {
  return (
    <ul className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 2xl:grid-cols-6 w-full">
      {images?.map((file, index) => (
        <li key={index} className="w-full">
          <UploadedImageCard file={file} onRemove={() => onRemove(index)} />
        </li>
      ))}
    </ul>
  );
};

const UploadedImageCard = ({
  file,
  onRemove,
}: {
  file: File;
  onRemove: () => void;
}) => {
  return (
    <div className="flex flex-col relative">
      <img
        src={URL.createObjectURL(file)}
        alt={file.name}
        className="object-cover rounded-lg shadow-sm mb-2 bg-white aspect-[3/4]"
      />
      <div className="flex justify-between gap-1">
        <span className="text-xs font-medium line-clamp-1">{file.name}</span>
        <span className="text-xs font-light shrink-0">
          {fileSize(file.size)}
        </span>
      </div>
      <button
        type="button"
        className="rounded-full bg-gray-700 hover:bg-gray-950 aspect-square h-5 absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 flex items-center justify-center transition-all duration-200"
        onClick={onRemove}
      >
        <FontAwesomeIcon
          icon={faX}
          className="text-white w-full text-xs"
        ></FontAwesomeIcon>
      </button>
    </div>
  );
};

export default ComicUpload;

import { useAllComicsInSeries } from '../comics/useAllComicsInSeries';

export const useSeriesNumComics = (seriesId?: string) => {
  const { comics } = useAllComicsInSeries(seriesId);

  const numComics = comics?.length ?? 0;

  return {
    numComics,
  };
};

import { supabase } from "@/lib/supabaseClient";
import { useQuery } from "@tanstack/react-query";

export const useRecentSeries = (count: number = 8) => {
    const { data: recentSeries, ...rest } = useQuery({
      queryKey: ['series', 'recent', { count }],
      queryFn: async () =>
        supabase
          .from('series')
          .select()
          .order('last_accessed', { ascending: false })
          .limit(count)
          .throwOnError()
          .then((v) => v.data),
    });
  
    return {
      recentSeries,
      ...rest,
    };
  };
  
// Frontend (React)
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { toast } from 'sonner';

const schema = z.object({
  email: z.string().email(),
  affiliation: z.string().nullable(),
  desired_source_language: z.string().nullable(),
  desired_target_language: z.string().nullable(),
  reason: z.string().nullable(),
});

type Schema = z.infer<typeof schema>;

export const useBetaSignupForm = () => {
  const form = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      // Important: Set default values to avoid uncontrolled input warnings
      email: '',
      affiliation: null,
      desired_source_language: null,
      desired_target_language: null,
      reason: null,
    },
  });

  const handleSubmit = async (data: Schema) => {
    // Submit all form data
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/messages/request-beta-access`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data), // Send all form data in the body
        }
      );

      if (!response.ok) {
        const errorData = await response.json(); // Try to parse error response
        if (response.status === 429) {
          toast.error('Too many requests', {
            description:
              'Please wait a while before attempting to send another message.',
          });
        } else if (response.status === 400 && errorData.message) {
          // Handle backend validation errors
          toast.error('Error', {
            description: errorData.message, // Display the backend message
          });
        } else {
          console.error('Backend Error:', errorData);
          throw new Error('Failed to send request'); // Generic error
        }
        return;
      }

      toast.info('Access Requested', {
        description:
          'We will review your request and get back to you via email with further details.',
      });
      form.reset(); // Clear the form after successful submission
    } catch (e) {
      console.error('Fetch Error:', e);
      toast.error('Failed to send request', {
        description: (
          <span>
            Please reach out to us on twitter at{' '}
            <a
              href={'https://twitter.com/LangPanel'}
              className="font-medium underline"
            >
              @LangPanel
            </a>
          </span>
        ),
      });
    }
  };

  return { form, handleSubmit };
};

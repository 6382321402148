import { create } from 'zustand';

// Define the state shape and actions
interface BeforeUnloadStoreState {
  /**
   * A set of unique strings representing reasons why the beforeunload
   * warning should be shown. If this set is not empty, the warning
   * should be displayed.
   */
  reasons: Set<string>;
  /**
   * Adds a reason to the set. If the reason already exists, the set remains unchanged.
   * @param reason - The unique string identifier for the reason.
   */
  addReason: (reason: string) => void;
  /**
   * Removes a reason from the set. If the reason does not exist, the set remains unchanged.
   * @param reason - The unique string identifier for the reason to remove.
   */
  removeReason: (reason: string) => void;
  /**
   * Helper function to check if there are any reasons to show the warning.
   * @returns true if the reasons set is not empty, false otherwise.
   */
  shouldShowWarning: () => boolean;
}

export const useBeforeUnloadStore = create<BeforeUnloadStoreState>()(
  (set, get) => ({
    reasons: new Set<string>(),

    addReason: (reason) =>
      set((state) => {
        const newReasons = new Set(state.reasons);
        newReasons.add(reason);
        if (newReasons.size !== state.reasons.size) {
          return { reasons: newReasons };
        }
        return {};
      }),

    removeReason: (reason) =>
      set((state) => {
        const newReasons = new Set(state.reasons);
        const deleted = newReasons.delete(reason);
        if (deleted) {
          return { reasons: newReasons };
        }
        return {};
      }),

    shouldShowWarning: () => {
      return get().reasons.size > 0;
    },
  })
);

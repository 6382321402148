import Logo from '@/components/icons/Logo';
import { LandingNavContainer } from '@/components/landing/Containers';
import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Link } from 'react-router-dom';

export const FloatingLogo = () => {
  return (
    <div className="w-dvw px-8 h-24 flex justify-center items-center fixed top-0 left-0 ">
      <LandingNavContainer className="flex justify-center lg:justify-start text-center lg:text-left">
        <Link to={'/'}>
          <Logo></Logo>
        </Link>
      </LandingNavContainer>
    </div>
  );
};

const NotFound = () => {
  return (
    <>
      <FloatingLogo />
      <div className="mx-2 h-dvh flex items-center justify-center flex-col ">
        <h1 className="text-8xl font-bold ">404</h1>
        <h2 className="text-2xl font-base sm:max-w-[400px] text-center mt-3">
          Sorry, the page you were looking for does not exist
        </h2>
        <Link
          className={cn(
            buttonVariants({ variant: 'default', size: 'lg' }),
            'mt-8'
          )}
          to="/"
        >
          Back to home
        </Link>
      </div>
    </>
  );
};

export default NotFound;

import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSession } from '@/hooks/useSession';
import { toast } from 'sonner';

const RequireAuth = ({ children }: { children: ReactNode }) => {
  const { session } = useSession();
  const navigate = useNavigate();

  const redirectToSignUp = () => {
    toast('You must be logged in to view that page');
    navigate('/sign-in', { replace: true });
  };

  useEffect(() => {
    let redirectId: ReturnType<typeof setTimeout> | null = null;
    if (!session) {
      redirectId = setTimeout(redirectToSignUp, 300);
    }

    return () => {
      if (redirectId) {
        clearTimeout(redirectId);
      }
    };
  }, [session]);

  return children;
};

export default RequireAuth;

import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import { toast } from 'sonner';
import { Tables } from '@/lib/database.types';
import { bubbleStoreActions } from '@/stores/bubbleStore';
import { OCRWorker } from '@/workers/ocr/ocrWorker';
import { LoadingBar, Progress } from '@/routes/viewer/components/AI/progress';
import { useBeforeUnloadStore } from '@/stores/beforeUnloadStore';
import useTutorialStore, { WAITING_OCR_ID } from '@/stores/tutorialStore';

function getOCR(
  imageURLs: string[],
  bubbles: Tables<'bubbles'>[],
  toastId?: number | string
) {
  const ocrWorker: OCRWorker = new Worker(
    new URL('@/workers/ocr/ocrWorker.ts', import.meta.url),
    { type: 'module' }
  );

  const ocrPromise = new Promise<Map<string, string>>((resolve, reject) => {
    if (!ocrWorker) {
      reject(Error('Failed to load worker for performing OCR.'));
    }

    ocrWorker.onmessage = (e) => {
      if (e.data.type === 'OCRdone') {
        resolve(e.data.texts);
        ocrWorker.terminate();
      } else if (e.data.type === 'image') {
        // const { imageData, bubbleId } = e.data;
        // saveImage(imageData, bubbleId);
      } else if (e.data.type === 'progress') {
        const progress = e.data.progress;
        const title = e.data.step;
        console.log(title);
        toast(
          <Progress
            title={title}
            subtitle={e.data.substep}
            progress={progress}
            animate={title !== 'Extracting image data...'}
          />,
          {
            dismissible: false,
            closeButton: false,
            id: toastId,
            duration: 100_000_000,
          }
        );
      } else if (e.data.type === 'loading') {
        const title = e.data.step;
        toast(<LoadingBar title={title} description="" />, {
          dismissible: false,
          closeButton: false,
          id: toastId,
          duration: 100_000_000,
        });
      } else if (e.data.type === 'error') {
        ocrWorker.terminate();
        reject(e.data.error);
      } else {
        console.error('Unexpected message from worker:', e.data);
        ocrWorker.terminate;
        throw Error('Unexpected message from OCR worker');
      }
    };

    ocrWorker.onerror = (error) => {
      reject(error);
      ocrWorker.terminate();
    };

    ocrWorker.postMessage({
      type: 'doOCR',
      urls: imageURLs,
      bubbles: bubbles,
    });
  });

  return { ocrPromise, ocrWorker };
}

interface OCRMutationParams {
  imageURLs: string[];
  bubbles: Tables<'bubbles'>[];
}

// name of task to store in global stores
const TASK_NAME = 'ocr-mutation';
export const useOCRMutation = (comicId: string, toastId?: number | string) => {
  const incrementIfNecessary = useTutorialStore(
    (state) => state.incrementIfNecessary
  );
  const addReason = useBeforeUnloadStore((state) => state.addReason);
  const removeReason = useBeforeUnloadStore((state) => state.removeReason);

  const mutationFn = useCallback(
    async ({ imageURLs, bubbles }: OCRMutationParams) => {
      const { ocrPromise } = getOCR(imageURLs, bubbles, toastId);
      const texts = await ocrPromise;
      console.log('got texts:', texts);
      const newBubbles = bubbles.map((bubble) => ({
        ...bubble,
        source_text: texts.get(bubble.id) ?? '',
        translated_text: '',
      }));
      bubbleStoreActions.setBubbles(newBubbles, comicId, true);
      return newBubbles;
    },
    [comicId, toastId]
  );

  return useMutation({
    mutationFn,
    onError: (error) => {
      toast.error('An error occurred while extracting text', {
        description: error.message,
        id: toastId,
        dismissible: true,
        closeButton: true,
        duration: 5000,
      });
    },
    onSuccess: () => {
      incrementIfNecessary(WAITING_OCR_ID);
      toast.success('Text extracted successfully', {
        id: toastId,
        dismissible: true,
        closeButton: true,
      });
    },
    onMutate: () => {
      addReason(TASK_NAME);
    },
    onSettled: () => {
      removeReason(TASK_NAME);
    },
  });
};

import { useMutation } from '@tanstack/react-query';
import { useSession } from '../useSession';
import { useCallback } from 'react';
import { toast } from 'sonner';
import { LoadingBar, Progress } from '@/routes/viewer/components/AI/progress';
import { Tables } from '@/lib/database.types';
import { bubbleStoreActions } from '@/stores/bubbleStore';
import { useBeforeUnloadStore } from '@/stores/beforeUnloadStore';
import {
  CanvasPage,
  PageSplitterWorker,
  PageSplitterWorkerRequest,
  PageSplitterWorkerResponse,
} from '@/workers/translation/pageSplitterWorker';
import useTutorialStore, {
  WAITING_TRANSLATION_ID,
} from '@/stores/tutorialStore';

/**
 * Creates a worker to split images into pages based on bubbles.
 * Handles communication and returns a promise resolving with the pages.
 */
function getSplitPages(
  imageData: (string | Blob)[],
  bubbles: Tables<'bubbles'>[],
  toastId?: number | string
): {
  pagesPromise: Promise<CanvasPage[]>;
  worker: PageSplitterWorker;
  objectUrlsToRevoke: string[];
} {
  // Instantiate the worker
  const worker: PageSplitterWorker = new Worker(
    new URL('@/workers/translation/pageSplitterWorker.ts', import.meta.url),
    { type: 'module' }
  ) as PageSplitterWorker;

  const objectUrlsToRevoke: string[] = [];
  const imageUrls: string[] = [];

  // Prepare image URLs, creating Object URLs for Blobs
  for (const item of imageData) {
    if (typeof item === 'string') {
      imageUrls.push(item);
    } else if (item instanceof Blob) {
      const url = URL.createObjectURL(item);
      imageUrls.push(url);
      objectUrlsToRevoke.push(url); // Track for cleanup
    }
  }

  const pagesPromise = new Promise<CanvasPage[]>((resolve, reject) => {
    if (!worker) {
      reject(new Error('Failed to initialize page splitter worker.'));
      // Revoke any created URLs if worker fails early
      objectUrlsToRevoke.forEach((url) => URL.revokeObjectURL(url));
      return;
    }

    const handleMessage = (e: MessageEvent<PageSplitterWorkerResponse>) => {
      switch (e.data.type) {
        case 'splitPagesSuccess':
          cleanup();
          resolve(e.data.payload);
          break;
        case 'splitPagesError':
          cleanup();
          reject(new Error(e.data.payload.message));
          break;
        case 'splitPagesProgress':
          // Update toast with progress using the main toastId
          toast(
            <Progress // Or LoadingBar if you prefer
              title="Preparing to translate..."
              subtitle={e.data.payload.message}
              progress={e.data.payload.progress ?? 0} // Add progress value if worker sends it
              animate={true}
            />,
            {
              id: toastId, // Update the main toast
              duration: 100_000_000,
              dismissible: false,
              closeButton: false,
            }
          );
          break;
        default:
          console.warn('Unexpected message from pageSplitterWorker:', e.data);
          break;
      }
    };

    const handleError = (e: ErrorEvent) => {
      console.error('Worker Error Event:', e);
      cleanup();
      reject(new Error(`Page Splitter Worker Error: ${e.message}`));
    };

    const cleanup = () => {
      worker.removeEventListener('message', handleMessage);
      worker.removeEventListener('error', handleError);
      worker.terminate(); // Terminate worker once done or errored
    };

    worker.addEventListener('message', handleMessage);
    worker.addEventListener('error', handleError);

    // --- Post message to worker ---
    const request: PageSplitterWorkerRequest = {
      type: 'splitPages',
      payload: { imageUrls, bubbles },
    };
    worker.postMessage(request);
  });

  return { pagesPromise, worker, objectUrlsToRevoke };
}

interface TranslationResponse {
  bubbles: Tables<'bubbles'>[];
}

const TASK_NAME = 'translation-mutation';
export const useTranslationMutation = (
  comicId: string,
  toastId?: number | string // ID for the main mutation toast
) => {
  const { session } = useSession();
  const addReason = useBeforeUnloadStore((state) => state.addReason);
  const removeReason = useBeforeUnloadStore((state) => state.removeReason);
  const incrementIfNecessary = useTutorialStore(
    (state) => state.incrementIfNecessary
  );

  // No need to manage worker instance here anymore, helper does it.

  const mutationFn = useCallback(
    async ({
      imageData, // Expect URLs or Blobs
      bubbles,
    }: {
      imageData: (string | Blob)[];
      bubbles: Tables<'bubbles'>[];
    }) => {
      // Show initial toast
      toast(
        <LoadingBar
          title="Processing..."
          description="Preparing image pages. This may take a moment."
        />,
        {
          id: toastId, // Use the main toast ID passed in
          duration: 100_000_000,
          closeButton: false,
          dismissible: false,
        }
      );

      if (!session) {
        throw new Error('Must be logged in to translate');
      }

      let pages: CanvasPage[];
      let objectUrlsToRevoke: string[] = [];

      try {
        // --- Step 1: Call the helper to get split pages ---
        const { pagesPromise, objectUrlsToRevoke: urls } = getSplitPages(
          imageData,
          bubbles,
          toastId
        );
        objectUrlsToRevoke = urls;
        pages = await pagesPromise;

        console.log(`Worker generated ${pages.length} pages.`);

        // --- Step 2: Update toast for translation step ---
        toast(
          <LoadingBar
            title="Translating..."
            description={`Translates ${pages.length} pages. This may take several minutes...`}
          />,
          {
            id: toastId, // Update the main toast
            duration: 100_000_000,
            closeButton: false,
            dismissible: false,
          }
        );

        // --- Step 3: Construct FormData for the *new* backend API ---
        const formData = new FormData();
        pages.forEach((page, index) => {
          formData.append(
            `page_image_${index}`,
            page.image,
            `page_${index}.${page.image.type.split('/')[1] || 'webp'}`
          );
          formData.append(
            `page_bubbles_${index}`,
            JSON.stringify(page.bubbles)
          );
          formData.append(
            `page_metadata_${index}`,
            JSON.stringify({
              region: page.region,
              total_height: page.total_height,
              width: page.width,
            })
          );
        });
        formData.append('page_count', pages.length.toString());

        // --- Step 4: Make the fetch call to the MODIFIED backend endpoint ---
        const response = await fetch(
          `${import.meta.env.VITE_BACKEND_URL}/translate`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${session.access_token}`,
            },
            body: formData,
          }
        );

        if (!response.ok) {
          const errorData = await response.json().catch(() => ({}));
          throw new Error(
            errorData.message ||
              `Translation failed: ${response.statusText} (Status: ${response.status})`
          );
        }

        const responseData: TranslationResponse = await response.json();
        return responseData.bubbles;
      } catch (error) {
        console.error('Error in translation mutation:', error);
        throw error;
      } finally {
        // --- Step 5: ALWAYS Clean up Object URLs ---
        console.log('Revoking object URLs:', objectUrlsToRevoke);
        objectUrlsToRevoke.forEach((url) => URL.revokeObjectURL(url));
      }
    },
    [session, toastId]
  );

  return useMutation({
    mutationFn,
    onError: (error: Error) => {
      toast.error('An error occurred', {
        description:
          error.message || 'Unknown error during translation process.',
        id: toastId,
        dismissible: true,
        closeButton: true,
        duration: 10000,
      });
    },
    onSuccess: (data) => {
      incrementIfNecessary(WAITING_TRANSLATION_ID);
      toast.success('Text translated successfully', {
        id: toastId,
        duration: 5000,
        dismissible: true,
        closeButton: true,
      });
      bubbleStoreActions.setBubbles(data, comicId, true);
    },
    onMutate: () => {
      addReason(TASK_NAME);
    },
    onSettled: () => {
      removeReason(TASK_NAME);
    },
  });
};

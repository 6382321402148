import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import './index.css';
import NewComic from './routes/dashboard/new-comic/new-comic';

const Dashboard = React.lazy(() => import('./routes/dashboard/dashboard'));
const Landing = React.lazy(() => import('./routes'));
const SignIn = React.lazy(() => import('./routes/sign-in'));
const Home = React.lazy(() => import('./routes/dashboard/home'));
const SeriesOverview = React.lazy(
  () => import('./routes/dashboard/series/series')
);
const SingleSeries = React.lazy(() => import('./routes/dashboard/series/[id]'));
const Viewer = React.lazy(() => import('./routes/viewer/viewer'));
const NotFound = React.lazy(() => import('./routes/404'));
const RequestPasswordReset = React.lazy(
  () => import('./routes/request-password-reset')
);
const ResetPassword = React.lazy(() => import('./routes/reset-password'));
const OAuthRedirect = React.lazy(() => import('./routes/oauth-redirect'));
import RequireAuth from './lib/RequireAuth';
import { AppQueryClientProvider } from './lib/reactQueryClient';
import SignUp from './routes/sign-up';
import { useBeforeUnloadStore } from './stores/beforeUnloadStore';
import { ToastProvider } from './components/ToastProvider';

const router = createBrowserRouter(
  [
    {
      element: <ToastProvider />,
      children: [
        {
          path: '/dashboard',
          element: (
            <RequireAuth>
              <React.Suspense fallback={null}>
                <Dashboard />
              </React.Suspense>
            </RequireAuth>
          ),
          children: [
            {
              path: 'new-comic/',
              element: (
                <React.Suspense fallback={null}>
                  <NewComic />
                </React.Suspense>
              ),
            },
            {
              path: 'series/:seriesId',
              element: (
                <React.Suspense fallback={null}>
                  <SingleSeries />
                </React.Suspense>
              ),
            },
            {
              path: 'series',
              element: (
                <React.Suspense fallback={null}>
                  <SeriesOverview />
                </React.Suspense>
              ),
            },
            {
              path: 'home',
              element: (
                <React.Suspense fallback={null}>
                  <Home />
                </React.Suspense>
              ),
            },
          ],
        },
        {
          path: '/view/:comicId',
          element: (
            <React.Suspense fallback={null}>
              <Viewer />
            </React.Suspense>
          ),
        },
        {
          path: '/reset-password',
          element: (
            <React.Suspense fallback={null}>
              <ResetPassword />
            </React.Suspense>
          ),
        },
        {
          path: '/oauth-redirect',
          element: (
            <React.Suspense fallback={null}>
              <OAuthRedirect />
            </React.Suspense>
          ),
        },
        {
          path: '/sign-in',
          element: (
            <React.Suspense fallback={null}>
              <SignIn />
            </React.Suspense>
          ),
        },
        {
          path: '/sign-up',
          element: (
            <React.Suspense fallback={null}>
              <SignUp />
            </React.Suspense>
          ),
        },
        {
          path: '/request-password-reset',
          element: (
            <React.Suspense fallback={null}>
              <RequestPasswordReset />
            </React.Suspense>
          ),
        },
        {
          path: '/',
          element: (
            <React.Suspense fallback={null}>
              <Landing />
            </React.Suspense>
          ),
        },
        {
          path: '*',
          element: (
            <React.Suspense fallback={null}>
              <NotFound />,
            </React.Suspense>
          ),
        },
      ],
    },
  ],
  {}
);

const App = () => {
  // *** Add the useEffect for the beforeunload listener here ***
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const reasons = useBeforeUnloadStore.getState().reasons;
      // console.log(reasons);

      if (reasons.size > 0) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <React.StrictMode>
      <AppQueryClientProvider>
        <RouterProvider router={router} />
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition="bottom-left"
        />
      </AppQueryClientProvider>
    </React.StrictMode>
  );
};

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);

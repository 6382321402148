import { Check, Plus, Search } from 'lucide-react';
import React, { useState, useEffect, useRef, FC } from 'react';
import { Control, Controller, ControllerRenderProps } from 'react-hook-form';
import { NewComicFormData } from '../../../forms/newComicFormData';
import { useRecentSeries } from '@/hooks/series/useRecentSeries';
import { Tables } from '@/lib/database.types';
import { useSeriesNumComics } from '@/hooks/series/useSeriesNumComics';
import { useSeriesSearch } from '@/hooks/series/useSeriesSearch';
import { useSearchParams } from 'react-router-dom';

interface SeriesListItemProps {
  series: Tables<'series'>;
  seriesField: ControllerRenderProps<NewComicFormData, 'series'>;
  setSearchQuery: (query: string) => void;
  setIsOpen: (isOpen: boolean) => void;
}

// Component for the series list items
const SeriesListItem: FC<SeriesListItemProps> = ({
  series,
  seriesField,
  setSearchQuery,
  setIsOpen,
}) => {
  const { numComics } = useSeriesNumComics(series.id);
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <button
      key={series.id}
      className="w-full px-6 py-3 text-left hover:bg-slate-50 flex items-start border-b last:border-b-0 border-slate-200"
      onClick={() => {
        seriesField.onChange(series);
        setSearchParams({ series: series.id });
        setSearchQuery('');
        setIsOpen(false);
      }}
    >
      <div className="flex flex-col">
        <span className="font-medium">{series.title}</span>
        <span className="text-sm text-slate-400">
          {numComics ?? 0} chapter{numComics === 1 ? '' : 's'}
        </span>
      </div>
      {seriesField.value === series && (
        <Check className="text-blue-500 w-6 ml-1" />
      )}
    </button>
  );
};

type SeriesSelectorProps = {
  control: Control<NewComicFormData>;
};

const SeriesSelector: React.FC<SeriesSelectorProps> = ({ control }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const { recentSeries } = useRecentSeries(5);
  const { series: searchedSeries } = useSeriesSearch(searchQuery);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <Controller
      name="series"
      control={control}
      rules={{
        validate: (value) =>
          value === undefined ||
          !!value ||
          'You need to select a series or create a new one',
      }}
      render={({ field, fieldState: { error } }) => (
        <div className="relative" ref={dropdownRef}>
          {isOpen ? (
            <div className="relative">
              <input
                ref={inputRef}
                type="text"
                placeholder="Search Series..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="bg-white w-full p-2 border border-slate-200 rounded pl-8 pr-10 placeholder:text-slate-500"
              />
              <Search className="h-5 w-5 absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400"></Search>
              <button
                onClick={() => setIsOpen(false)}
                className="absolute right-2 top-1/2 transform -translate-y-1/2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          ) : (
            <button
              onClick={() => setIsOpen(true)}
              className="w-full pl-3 pr-2 py-2 border border-slate-200 rounded-lg text-slate-500 flex justify-between items-center bg-white"
            >
              <span>
                {field.value === undefined
                  ? 'Create New Series'
                  : field.value.title || 'Select series...'}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}
          {isOpen && (
            <div className="absolute right-1 z-10 max-w-[400px] w-full bg-white border border-slate-200 rounded shadow-lg text-slate-600 -translate-y-full -mt-[50px]">
              <div className="max-h-[500px] overflow-auto">
                <button
                  onClick={() => {
                    setSearchParams({});
                    field.onChange(undefined);
                    setIsOpen(false);
                  }}
                  className="w-full px-4 py-4 text-left hover:bg-slate-50 flex items-center border-b border-slate-200"
                >
                  <Plus className="w-5 h-5 text-slate-500 mr-1" />
                  Create New Series
                </button>
                <h3 className="px-4 py-4 text-sm font-semibold text-slate-700 bg-slate-50 border-b border-slate-200">
                  {searchQuery !== '' ? 'Search Results' : 'Recent Series'}
                </h3>
                {!recentSeries || recentSeries.length === 0 ? (
                  <p className="px-4 py-3 text-center text-sm text-gray-500">
                    No series found.
                  </p>
                ) : searchQuery === '' ? (
                  recentSeries.map((series) => (
                    <SeriesListItem
                      key={series.id}
                      series={series}
                      seriesField={field}
                      setSearchQuery={setSearchQuery}
                      setIsOpen={setIsOpen}
                    />
                  ))
                ) : (
                  searchedSeries?.map((series) => (
                    <SeriesListItem
                      key={series.id}
                      series={series}
                      seriesField={field}
                      setSearchQuery={setSearchQuery}
                      setIsOpen={setIsOpen}
                    />
                  ))
                )}
              </div>
            </div>
          )}
          {error && <p className="text-red-500 mt-2">{error.message}</p>}
        </div>
      )}
    />
  );
};

export default SeriesSelector;

import { useYoloMutation } from '@/hooks/ai/useYoloMutation';
import { useOCRMutation } from '@/hooks/ai/useOCRMutation';
import { useTranslationMutation } from '@/hooks/ai/useTranslateMutation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Tables } from '@/lib/database.types';
import { toast } from 'sonner';
import { LoadingBar } from '@/routes/viewer/components/AI/progress';
import { useCreateComic } from '@/hooks/comics/useCreateComic';
import { Link, useNavigate } from 'react-router-dom';
import { useStore } from 'zustand';
import { NewComicFormData } from '@/forms/newComicFormData';
import comicUploadStore from '@/stores/comicUploadStore';
import { buttonVariants } from '@/components/ui/button';
import { useState } from 'react';
import { uuid } from '@supabase/supabase-js/src/lib/helpers';
import {
  WebpConverterWorker,
  WebpWorkerMessage,
  WebpWorkerResponse,
  WebpWorkerResultItem,
} from '@/workers/imageConversionWorker';
import { useBeforeUnloadStore } from '@/stores/beforeUnloadStore';

/**
 * Converts an array of image Files to WebP format using a Web Worker.
 *
 * @param files The array of File objects to convert.
 * @param quality The WebP quality setting (0.0 to 1.0, default 0.8).
 * @returns A Promise resolving with an array of converted WebP File objects.
 * @rejects {Error} If any file fails conversion or a worker error occurs.
 */
async function convertImages(
  files: File[],
  quality: number = 0.8
): Promise<File[]> {
  // Create a new worker instance for this conversion task.
  // NOTE: Ensure the path '@workers/imageConversionWorker.ts' is correct
  // and your build system handles TypeScript workers (e.g., using Vite).
  // If it's a plain JS worker, use '.js'.
  const conversionWorker = new Worker(
    new URL('@/workers/imageConversionWorker.ts', import.meta.url), // User-specified path
    { type: 'module' }
  ) as WebpConverterWorker; // Cast to our typed interface

  console.log(
    `[convertImages] Worker created. Converting ${files.length} files with quality ${quality}...`
  );

  // Return a Promise that resolves/rejects based on worker communication.
  return new Promise<File[]>((resolve, reject) => {
    // Handle messages from the worker
    conversionWorker.onmessage = (event: MessageEvent<WebpWorkerResponse>) => {
      console.log('[convertImages] Message received:', event.data);

      // Check for the expected response type
      if (event.data?.type !== 'convertDone') {
        console.warn(
          '[convertImages] Received unexpected message type:',
          event.data?.type
        );
        // Decide how to handle unexpected messages - ignore or reject? Let's ignore for now.
        return;
      }

      const { success, results, error: workerErrorMsg } = event.data;

      // Terminate worker now that we have the final message
      conversionWorker.terminate();
      console.log('[convertImages] Worker terminated after receiving message.');

      if (!success || !results) {
        reject(
          new Error(
            workerErrorMsg ||
              'WebP worker reported failure or sent invalid results.'
          )
        );
        return;
      }

      // Process results strictly: fail if any single file failed
      const successfulFiles: File[] = [];
      const errors: string[] = [];

      results.forEach((res: WebpWorkerResultItem) => {
        if (res.file) {
          successfulFiles.push(res.file);
        } else {
          errors.push(
            `Failed to convert ${res.originalName}: ${res.error || 'Unknown error'}`
          );
        }
      });

      if (errors.length > 0) {
        // Reject the promise if any conversion failed
        reject(
          new Error(
            `Conversion failed for ${errors.length} file(s): ${errors.join('; ')}`
          )
        );
      } else {
        // Resolve the promise with the array of successfully converted files
        console.log(
          `[convertImages] All ${successfulFiles.length} files converted successfully.`
        );
        resolve(successfulFiles);
      }
    };

    // Handle errors from the worker itself
    conversionWorker.onerror = (error: ErrorEvent) => {
      console.error('[convertImages] Worker error event:', error);
      // Terminate worker on error
      conversionWorker.terminate();
      console.log('[convertImages] Worker terminated due to error event.');
      reject(
        new Error(
          `WebP Worker error: ${error.message || 'Unknown worker error'}`
        )
      );
    };

    // Post the message to start the conversion
    const message: WebpWorkerMessage = {
      type: 'doConvert',
      files: files,
      quality: quality,
    };
    conversionWorker.postMessage(message);
    console.log('[convertImages] Message posted to worker.');
  });
}

const TASK_NAME = 'comic-create-mutation';
/**
 * Compound mutation which simply combines the createComibMutation
 * and the AI based mutations, and shows a nice progress toast while
 * performing the mutations.
 *
 * @returns Mutation for uploading comic then doing translation on it
 */
export const useComicCreateMutation = (toastId: number | string) => {
  const [comicId] = useState<string>(uuid());
  const addReason = useBeforeUnloadStore((state) => state.addReason);
  const removeReason = useBeforeUnloadStore((state) => state.removeReason);

  const queryClient = useQueryClient();
  const createComicMutation = useCreateComic(comicId, toastId);
  const yoloMutation = useYoloMutation(comicId, toastId);
  const ocrMutation = useOCRMutation(comicId, toastId);
  const translateMutation = useTranslationMutation(comicId, toastId);
  const navigate = useNavigate();

  // load user uploaded images from store as initial values
  const setUserUploadedImages = useStore(
    comicUploadStore,
    (state) => state.setUserUploadedImages
  );

  const mutationFn = async (data: NewComicFormData) => {
    toast(<LoadingBar title="Optimizing images..." description="" />, {
      id: toastId,
      description: undefined,
      dismissible: false,
      closeButton: false,
      duration: 100_000_000,
      action: undefined,
    });

    data.files = await convertImages(data.files);

    toast(<LoadingBar title="Adding comic to database..." description="" />, {
      id: toastId,
      description: undefined,
      dismissible: false,
      closeButton: false,
      duration: 100_000_000,
    });

    let comic: Tables<'comics'>;

    try {
      comic = await createComicMutation.mutateAsync(data);
      queryClient.invalidateQueries({ queryKey: ['series'] });
      queryClient.invalidateQueries({ queryKey: ['comics'] });
      navigate(`/dashboard/series/${comic.series_id}`);
      window.scrollTo(0, 0);
    } catch (e) {
      console.error(e);
      toast.error('An error occurred attempting to create the new comic', {
        id: toastId,
        description: (e as Error)?.message ?? 'An unknown error occured.',
      });
      return;
    }

    const imageURLs = data.files.map((file) => URL.createObjectURL(file));

    // these mutations already handle their errors
    let bubbles = await yoloMutation.mutateAsync({ imageURLs });
    bubbles = await ocrMutation.mutateAsync({
      bubbles,
      imageURLs,
    });
    await translateMutation.mutateAsync({
      imageData: data.files,
      bubbles,
    });

    setUserUploadedImages([]);
    return { comic: comic, toastId: toastId };
  };

  return useMutation({
    mutationFn,
    onSuccess: (data) => {
      toast.success('Comic translated successfully', {
        id: toastId,
        description: undefined,
        dismissible: true,
        closeButton: true,
        action: (
          <Link
            to={`/view/${data?.comic?.id}`}
            className={buttonVariants({ variant: 'outline' })}
          >
            View Comic
          </Link>
        ),
      });
    },
    onMutate: () => {
      addReason(TASK_NAME);
    },
    onSettled: () => {
      removeReason(TASK_NAME);
    },
  });
};

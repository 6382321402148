import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import { toast } from 'sonner';
import { Tables } from '@/lib/database.types';
import { bubbleStoreActions } from '@/stores/bubbleStore';
import { saveImage } from './saveImage';
import { YoloWorker } from '@/workers/yolo/yoloWorker';
import { uuid } from '@supabase/supabase-js/src/lib/helpers';
import { YoloBox } from '@/workers/yolo/postProcess';
import { LoadingBar, Progress } from '@/routes/viewer/components/AI/progress';
import { useBeforeUnloadStore } from '@/stores/beforeUnloadStore';
import useTutorialStore, { WAITING_YOLO_ID } from '@/stores/tutorialStore';

// name of task to store in global stores
const TASK_NAME = 'yolo-mutation';

function getYolo(imageURLs: string[], toastId?: string | number) {
  const yoloWorker: YoloWorker = new Worker(
    new URL('@/workers/yolo/yoloWorker.ts', import.meta.url),
    { type: 'module' }
  );

  const yoloPromise = new Promise<YoloBox[]>((resolve, reject) => {
    if (!yoloWorker) {
      reject(Error('Failed to load worker for performing OCR.'));
    }

    yoloWorker.onmessage = (e) => {
      if (e.data.type === 'yoloDone') {
        resolve(e.data.detections);
        yoloWorker.terminate();
      } else if (e.data.type === 'image') {
        const { imageData, id } = e.data;
        saveImage(imageData, id);
      } else if (e.data.type === 'progress') {
        const progress = e.data.progress;
        const title = e.data.step;
        console.log(title);
        toast(
          <Progress
            title={title}
            progress={progress}
            animate={title !== 'Extracting image data...'}
          />,
          {
            dismissible: false,
            id: toastId,
            duration: 100_000_000,
          }
        );
      } else if (e.data.type === 'loading') {
        const title = e.data.step;
        toast(<LoadingBar title={title} description="" />, {
          dismissible: false,
          id: toastId,
          duration: 100_000_000,
        });
      } else if (e.data.type === 'error') {
        yoloWorker.terminate();
        reject(e.data.error);
      } else {
        console.error('Unexpected message from worker:', e.data);
        yoloWorker.terminate();
      }
    };

    yoloWorker.onerror = (error) => {
      reject(error);
      yoloWorker.terminate();
    };

    yoloWorker.postMessage({
      type: 'doYolo',
      urls: imageURLs,
    });
  });

  return { ocrPromise: yoloPromise, ocrWorker: yoloWorker };
}

interface YoloMutationParams {
  imageURLs: string[];
  toastId?: string | number;
}

export const useYoloMutation = (comicId: string, toastId: string | number) => {
  const incrementIfNecessary = useTutorialStore(
    (state) => state.incrementIfNecessary
  );
  const addReason = useBeforeUnloadStore((state) => state.addReason);
  const removeReason = useBeforeUnloadStore((state) => state.removeReason);

  const mutationFn = useCallback(
    async ({ imageURLs }: YoloMutationParams) => {
      const { ocrPromise } = getYolo(imageURLs, toastId);
      const detections = await ocrPromise;
      const bubbles: Tables<'bubbles'>[] = detections.map((detection) => ({
        class: 'dialog',
        comic_id: comicId,
        confidence: detection.confidence,
        height: detection.y_max - detection.y_min,
        width: detection.x_max - detection.x_min,
        start_x: detection.x_min,
        start_y: detection.y_min,
        id: uuid(),
        source_text: '',
        translated_text: '',
      }));
      bubbleStoreActions.setBubbles(bubbles, comicId, true);
      return bubbles;
    },
    [comicId, toastId]
  );

  return useMutation({
    mutationFn,

    onError: (error) => {
      toast.error('An error occurred while detecting text blocks', {
        description: error.message,
        id: toastId,
        duration: 5000,
        dismissible: true,
        closeButton: true,
      });
    },
    onSuccess: () => {
      incrementIfNecessary(WAITING_YOLO_ID);
      toast.success('Text blocks detected successfully', {
        id: toastId,
        description: undefined,
        dismissible: true,
        closeButton: true,
      });
    },
    onMutate: () => {
      addReason(TASK_NAME);
    },
    onSettled: () => {
      removeReason(TASK_NAME);
    },
  });
};

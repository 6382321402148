export function saveImage(imageData: ImageData, id: string) {
  // Create a canvas to convert ImageData to Blob
  const canvas = document.createElement('canvas');
  canvas.width = imageData.width;
  canvas.height = imageData.height;
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.putImageData(imageData, 0, 0);
    // Convert canvas to Blob
    canvas.toBlob((blob) => {
      if (blob) {
        // Create a download link
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${id.slice(0, 8)}.jpg`; // Filename with bubble ID
        a.style.display = 'none'; // Hide the link
        // Append to body, trigger click, and remove
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        // Release the URL object
        URL.revokeObjectURL(url);
      } else {
        console.error('Failed to create Blob from canvas.');
      }
    }, 'image/jpeg'); // Specify PNG format
  } else {
    console.error('Canvas context not available.');
  }
}

import { supabase } from '@/lib/supabaseClient';
import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '../utils/debounce';

export const useSeriesSearch = (query: string, debounce = 500) => {
  const debouncedQuery = useDebounce<string>(query, debounce);

  const { data: series, ...rest } = useQuery({
    queryKey: ['series', 'search', { query: debouncedQuery }],
    queryFn: async () => {
      return supabase
        .from('series')
        .select()
        .textSearch('title', debouncedQuery)
        .then((d) => d.data);
    },
    enabled: query !== '',
  });

  return {
    series,
    ...rest,
  };
};

import { useEffect, useRef } from 'react';

interface ProgressProps {
  title: string;
  progress: number;
  animate: boolean;
  subtitle?: string;
}

export const Progress = ({
  progress,
  title,
  animate,
  subtitle,
}: ProgressProps) => {
  return (
    <>
      <progress
        value={progress}
        max="100"
        className={`[&::-webkit-progress-bar]:rounded-r-sm [&::-webkit-progress-bar]:inset-shadow-sm [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:rounded-sm [&::-webkit-progress-value]:bg-blue-400 h-1 absolute bottom-0 left-0 w-full ${animate ? '[&::-webkit-progress-value]:duration-75 [&::-webkit-progress-value]:transition-all' : '[&::-webkit-progress-value]:transition-none'}`}
      ></progress>
      <h1 className="text-base">{title}</h1>
      <div className="flex gap-2 items-center">
        <p className="text-sm text-slate-500">
          {progress.toFixed(0)}% complete {subtitle && `— ${subtitle}`}
        </p>
      </div>
    </>
  );
};

interface LoadingBarProps {
  title: string;
  description: string;
}

export const LoadingBar = ({ title, description }: LoadingBarProps) => {
  const indicatorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const indicator = indicatorRef.current;
    if (!indicator) return;

    let position = 0; // Percentage (0 to 100 representing the indicator's left position)
    let direction = 1; // 1 for right, -1 for left
    const step = 0.8; // Adjust for speed
    const indicatorWidthPercentage = 40;

    const animate = () => {
      position += direction * step;

      // The indicator's left edge should go from 0% to (100% - indicatorWidthPercentage)%
      const maxLeft = 100 - indicatorWidthPercentage;

      if (position > maxLeft) {
        position = maxLeft;
        direction = -1;
      } else if (position < 0) {
        position = 0;
        direction = 1;
      }

      indicator.style.left = `${position}%`;
      requestAnimationFrame(animate);
    };

    requestAnimationFrame(animate);
  });

  return (
    <>
      <div className="absolute h-1 left-0 bottom-0 w-full bg-slate-100 rounded-sm overflow-hidden">
        <div
          ref={indicatorRef}
          className="absolute bg-blue-400 h-full w-[40%]"
          style={{ left: '0%' }}
        ></div>
      </div>
      <h1 className="text-base">{title}</h1>
      <div className="flex gap-2 items-center mt-1">
        <p className="text-sm text-slate-500">{description}</p>
      </div>
    </>
  );
};

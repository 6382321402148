import {
  Controller,
  Control,
  FieldErrors,
  UseFormWatch,
} from 'react-hook-form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import SeriesSelector from './SeriesSelector';
import { NewComicFormData } from '../../../forms/newComicFormData';

export const ExpandableSeriesSection = ({
  control,
  errors,
  watch,
}: {
  control: Control<NewComicFormData>;
  errors: FieldErrors<NewComicFormData>;
  watch: UseFormWatch<NewComicFormData>;
}) => {
  const series = watch('series');
  const isExpanded = series === undefined;

  return (
    <div className="p-4 sm:p-8 bg-slate-50 rounded-lg flex flex-col gap-6 relative shadow-sm transition-all duration-300 ease-in-out border border-slate-200">
      <div className="flex flex-col">
        <h2 className="text-xl text-slate-600 font-semibold">Step 3</h2>
        <h3 className="text-sm text-slate-400 font-bold">
          Select Series For New Comic
        </h3>
      </div>

      <SeriesSelector control={control} />

      {isExpanded && (
        <div className="flex flex-col gap-3 sm:gap-4">
          <h4 className="text-slate-600 font-medium mb-1">
            New Series Information
          </h4>
          <div className="flex flex-col sm:flex-row gap-3 sm:gap-4">
            <Controller
              name="newSeries.title"
              control={control}
              rules={{
                validate: (value) =>
                  series === undefined && (!value || value.trim() === '')
                    ? 'Series title is required'
                    : true,
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  autoComplete="off"
                  placeholder="*Series Title"
                  className="flex-[2]"
                />
              )}
            />
            <Controller
              name="newSeries.author"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  autoComplete="off"
                  placeholder="Author (optional)"
                  className="flex-1"
                />
              )}
            />
          </div>
          <Controller
            name="newSeries.description"
            control={control}
            render={({ field }) => (
              <Textarea
                {...field}
                autoComplete="off"
                placeholder="Description (optional)"
                spellCheck={false}
              />
            )}
          />
        </div>
      )}
      {errors.newSeries?.title && (
        <p className="text-red-500">{errors.newSeries.title.message}</p>
      )}
    </div>
  );
};

import { create } from 'zustand';

export const VIEW_MODE_TUTORIAL_ID = 'view-mode-button';
export const EDIT_MODE_TUTORIAL_ID = 'edit-mode-button';
export const GENERAL_MESSAGE = 'general-message';
export const DELETE_BUBBLE_POPUP_ID = 'delete-bubble-popup';
export const DELETE_ALL_ID = 'delete-all-popup';
export const UNDO_ACTION_ID = 'undo-action';
export const REDO_ACTION_ID = 'redo-action';
export const AI_ACTIONS_ID = 'ai-actions';
export const YOLO_ID = 'yolo';
export const OCR_ID = 'ocr';
export const TRANSLATE_ID = 'translate';
export const WAITING_YOLO_ID = 'waiting-yolo';
export const WAITING_OCR_ID = 'waiting-ocr';
export const WAITING_TRANSLATION_ID = 'waiting-translation';

interface TextElement {
  text: string;
  href?: string;
}

interface ParagraphElement {
  texts: TextElement[];
}

export interface TutorialStep {
  id: string;
  title: string;
  description: ParagraphElement[];
  continueButtonTitle?: string;
}

interface TutorialState {
  steps: TutorialStep[];
  currentStep: number;
  setSteps: (steps: TutorialStep[]) => void;
  setCurrentStep: (step: number) => void;
  incrementStep: () => void;
  decrementStep: () => void;
  getCurrentStepData: () => TutorialStep | undefined;
  incrementIfNecessary: (id: string) => void;
  decrementIfNecessary: (ids: string[]) => void;
}

const useTutorialStore = create<TutorialState>((set, get) => ({
  steps: [],
  currentStep: 0,
  setSteps: (steps) => set({ steps }),
  setCurrentStep: (step: number) => set({ currentStep: step }),
  incrementStep: () =>
    set((state) => ({
      currentStep: state.currentStep + 1,
    })),
  decrementStep: () => set((state) => ({ currentStep: state.currentStep - 1 })),
  getCurrentStepData: () => {
    const { steps, currentStep } = get();
    if (currentStep >= 0 && currentStep < steps.length) {
      return steps[currentStep];
    }
    return undefined;
  },
  incrementIfNecessary: (id: string) => {
    const currentStepData = get().getCurrentStepData();
    if (currentStepData && currentStepData.id === id) {
      set((state) => ({
        currentStep: state.currentStep + 1,
      }));
    }
  },
  decrementIfNecessary: (ids: string[]) => {
    const currentStepData = get().getCurrentStepData();
    if (
      currentStepData &&
      ids.find((id) => id === currentStepData.id) !== undefined
    ) {
      set((state) => ({
        currentStep: state.currentStep - 1,
      }));
    }
  },
}));

export default useTutorialStore;

import { supabase } from '@/lib/supabaseClient';
import { useQuery } from '@tanstack/react-query';

export const useSeries = (seriesId?: string) => {
  const {
    data: series,
    isLoading: seriesLoading,
    error: seriesFetchError,
  } = useQuery({
    queryKey: ['series', { seriesId }],
    queryFn: async () =>
      supabase
        .from('series')
        .select()
        .eq('id', seriesId!)
        .single()
        .throwOnError()
        .then((val) => val.data),
    enabled: !!seriesId,
  });

  return { series, seriesLoading, seriesFetchError };
};

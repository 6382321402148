import { Tables } from '@/lib/database.types';
import { supabase } from '@/lib/supabaseClient';
import { create } from 'zustand';
import { CreateLocation } from './editorStore';
import { PostgrestError } from '@supabase/supabase-js';
import { toast } from 'sonner';

const sortBubbles = (bubbles: Tables<'bubbles'>[]): Tables<'bubbles'>[] => {
  bubbles.sort((a, b) => {
    const threshold = a.height / 5;

    if (Math.abs(a.start_y - b.start_y) <= threshold) {
      // Tie-breaker based on start_x
      return a.start_x - b.start_x;
    } else {
      return a.start_y - b.start_y;
    }
  });

  return bubbles;
};

const deleteBubble = (bubble?: Tables<'bubbles'>): void =>
  useBubbleStore.setState((state) => {
    if (!bubble) return {};

    // Find the index of the bubble to be deleted
    const bubbleIndex = state.bubblesList.findIndex((b) => b.id === bubble.id);

    // Remove the bubble from the list
    const newList = state.bubblesList.filter((b) => b.id !== bubble.id);

    let nextSelectedBubble: Tables<'bubbles'> | undefined;
    if (newList.length > 0) {
      if (bubbleIndex < newList.length) {
        // If there is a bubble after the deleted one, select it
        nextSelectedBubble = newList[bubbleIndex];
      } else {
        // Otherwise, select the previous bubble (if it exists)
        nextSelectedBubble = newList[bubbleIndex - 1];
      }
    }

    // Update the state with the new list and selected bubble
    return {
      bubblesList: newList,
      bubblesMap: newList.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
      selectedBubble: nextSelectedBubble,
    };
  });

const deleteBubbleAction = (bubble: Tables<'bubbles'>): Action => {
  // the index we need to insert the bubble into
  const index = useBubbleStore
    .getState()
    .bubblesList.findIndex((b) => b.id === bubble?.id);

  return {
    type: 'deleteBubble',
    do: () => deleteBubble(bubble),
    undo: () => {
      // Ensure bubble is reinserted at the original index during undo
      if (index !== -1 && bubble) {
        createBubble(bubble, index);
        selectBubble(bubble);
      } else {
        // Fallback if the original index is not valid anymore
        createBubble(bubble, 'end');
        selectBubble(bubble);
      }
    },
    persist: [{ type: 'delete', bubble: bubble, key: bubble.id }],
  };
};

const createBubble = (
  bubble: Tables<'bubbles'>,
  location: CreateLocation | number = 'after-selected'
): void => {
  useBubbleStore.setState((state) => {
    let newList = [...state.bubblesList];

    // Add the new bubble based on the specified location
    if (typeof location === 'number') {
      newList.splice(location, 0, bubble);
    } else {
      const selectedIndex = state.selectedBubble
        ? state.bubblesList.findIndex((b) => b.id === state.selectedBubble?.id)
        : -1;

      switch (location) {
        case 'start':
          newList.unshift(bubble);
          break;
        case 'end':
          newList.push(bubble);
          break;
        case 'after-selected':
          if (selectedIndex !== -1) {
            newList.splice(selectedIndex + 1, 0, bubble);
          } else {
            newList.push(bubble);
          }
          break;
        case 'before-selected':
          if (selectedIndex !== -1) {
            newList.splice(selectedIndex, 0, bubble);
          } else {
            newList.unshift(bubble);
          }
          break;
      }
    }

    // Sort the bubbles again
    newList = sortBubbles(newList);

    // Create a new map of the sorted bubbles
    const newMap = newList.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur }),
      {}
    );

    return {
      bubblesList: newList,
      bubblesMap: newMap,
    };
  });
};

const createBubbleAction = (
  bubble: Tables<'bubbles'>,
  location?: CreateLocation | number
): Action => {
  return {
    type: 'createBubble',
    do: () => createBubble(bubble, location),
    undo: () => deleteBubble(bubble),
    persist: [
      {
        type: 'create',
        bubble: bubble,
        key: bubble.id,
      },
    ],
  };
};

const updateBubble = (bubble: Tables<'bubbles'>): void => {
  useBubbleStore.setState((state) => {
    const newList = state.bubblesList.map((b) =>
      b.id === bubble.id ? bubble : b
    );
    const newMap = newList.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur }),
      {}
    );

    return {
      ...state,
      bubblesList: newList,
      bubblesMap: newMap,
    };
  });
};

const updateBubbleAction = (bubble: Tables<'bubbles'>): Action => {
  const prevState = useBubbleStore
    .getState()
    .bubblesList.find((b) => b.id === bubble.id);

  if (!prevState) {
    throw Error('Bubble does not exist.');
  }

  return {
    type: 'updateBubble',
    do: () => updateBubble(bubble),
    undo: () => updateBubble(prevState),
    persist: [
      {
        type: 'update',
        key: bubble.id,
        to: bubble,
        from: prevState,
      },
    ],
  };
};

const setBubbles = (bubbles: Tables<'bubbles'>[]) => {
  useBubbleStore.setState(() => {
    sortBubbles(bubbles);
    return {
      bubblesList: bubbles,
      bubblesMap: bubbles.reduce<Record<string, Tables<'bubbles'>>>(
        (acc, bubble) => {
          acc[bubble.id] = bubble;
          return acc;
        },
        {}
      ),
    };
  });
};

const setBubblesAction = (
  bubbles: Tables<'bubbles'>[],
  comicId: string,
  persist: boolean
): Action => {
  const prevBubbles = useBubbleStore.getState().bubblesList;

  return {
    type: 'setBubbles',
    do: () => setBubbles(bubbles),
    undo: () => setBubbles(prevBubbles),
    persist: persist
      ? [
          {
            type: 'replace',
            key: comicId,
            to: bubbles,
            from: prevBubbles,
          },
        ]
      : [],
  };
};

const selectBubble = (bubble: Tables<'bubbles'> | undefined) => {
  useBubbleStore.setState(() => ({
    selectedBubble: bubble,
  }));
};

const selectBubbleAction = (bubble: Tables<'bubbles'> | undefined): Action => {
  const prevSelected = useBubbleStore.getState().selectedBubble;

  return {
    type: 'selectBubble',
    do: () => selectBubble(bubble),
    undo: () => selectBubble(prevSelected),
    persist: [],
  };
};

type Update = {
  type: 'update';
  key: string;
  to: Omit<Tables<'bubbles'>, 'id'>;
  from: Omit<Tables<'bubbles'>, 'id'>;
};

type Create = {
  type: 'create';
  key: string;
  bubble: Omit<Tables<'bubbles'>, 'id'>;
};

type Delete = {
  type: 'delete';
  key: string;
  bubble: Omit<Tables<'bubbles'>, 'id'>;
};

type Replace = {
  type: 'replace';
  key: string;
  to: Tables<'bubbles'>[];
  from: Tables<'bubbles'>[];
};

type PersistableAction = Update | Create | Delete | Replace;

type Action = {
  type: string;
  do: () => void;
  undo: () => void;
  // the atomic steps needed to persist the action,
  // listed in the order that they need to be performed
  persist: PersistableAction[];
};

// Generic function to reverse an action
function reverseAction(
  persistableAction: PersistableAction
): PersistableAction {
  if (persistableAction.type === 'create') {
    return {
      type: 'delete',
      bubble: persistableAction.bubble,
      key: persistableAction.key,
    };
  } else if (persistableAction.type === 'delete') {
    return {
      type: 'create',
      bubble: persistableAction.bubble,
      key: persistableAction.key,
    };
  } else if (persistableAction.type === 'update') {
    return {
      type: 'update',
      to: persistableAction.from,
      from: persistableAction.to,
      key: persistableAction.key,
    };
  } else {
    // type === 'replace'
    return {
      type: 'replace',
      to: persistableAction.from,
      from: persistableAction.to,
      key: persistableAction.key,
    };
  }
}

async function persistAction(
  persistableAction: PersistableAction
): Promise<void> {
  let error: PostgrestError | null = null;
  let result: { error: PostgrestError | null };

  if (persistableAction.type === 'create') {
    result = await supabase
      .from('bubbles')
      .insert({ id: persistableAction.key, ...persistableAction.bubble });
  } else if (persistableAction.type === 'delete') {
    result = await supabase
      .from('bubbles')
      .delete()
      .eq('id', persistableAction.key);
  } else if (persistableAction.type === 'update') {
    result = await supabase
      .from('bubbles')
      .update({ ...persistableAction.to, id: persistableAction.key })
      .eq('id', persistableAction.key);
  } else {
    // type === 'replace'
    // replace all the bubbles
    console.log('trying to persist replace action!');
    console.log(`key:`, persistableAction.key);
    result = await supabase
      .from('bubbles')
      .delete()
      .eq('comic_id', persistableAction.key);

    if (!result.error) {
      result = await supabase.from('bubbles').insert(persistableAction.to);
    }
  }

  error = result.error;

  if (error) {
    throw error;
  }
}

const doAction = async (action: Action, history: boolean) => {
  action.do();
  // if this action is stored in the undo history,
  // manage the history, potentially persisting the change
  // to the database.
  if (history) {
    useBubbleStore.setState((state) => ({
      undoStack: [...state.undoStack, action],
      redoStack: [],
    }));
  }

  useBubbleStore.setState((state) => ({
    awaitingPersist: [...state.awaitingPersist, ...action.persist],
  }));
};

const undoAction = async () => {
  const undoStack = useBubbleStore.getState().undoStack;
  if (undoStack.length > 0) {
    const action = undoStack[undoStack.length - 1]; // Access last element without popping
    useBubbleStore.setState((state) => ({
      undoStack: undoStack.slice(0, -1), // Create a new array without the last element
      redoStack: [...state.redoStack, action],
      awaitingPersist: [
        ...state.awaitingPersist,
        ...action.persist.map((persist) => reverseAction(persist)),
      ],
    }));
    action.undo();
  }
};

const redoAction = async () => {
  const redoStack = useBubbleStore.getState().redoStack;
  if (redoStack.length > 0) {
    const action = redoStack[redoStack.length - 1]; // Access last element without popping
    action.do();
    useBubbleStore.setState((state) => ({
      undoStack: [...state.undoStack, action],
      redoStack: redoStack.slice(0, -1), // Create a new array without the last element
      awaitingPersist: [...state.awaitingPersist, ...action.persist],
    }));
  }
};

const simplifyAwaitingPersist = (
  actions: PersistableAction[]
): PersistableAction[] => {
  const actionMap = new Map<string, PersistableAction>();

  for (const action of actions) {
    const { key, type } = action;

    if (type === 'replace') {
      // If we encounter a replace action, clear the map and add only this action
      actionMap.clear();
      actionMap.set(key, action);
    } else if (!actionMap.has(key) || actionMap.get(key)?.type !== 'replace') {
      // Only process create, update, delete if no replace action for this key exists
      const existingAction = actionMap.get(key);

      if (type === 'delete') {
        actionMap.set(key, action);
      } else if (type === 'create') {
        actionMap.set(key, action);
      } else if (type === 'update') {
        if (!existingAction) {
          actionMap.set(key, action);
          continue;
        }

        if (existingAction.type === 'delete') {
          continue; // it doesn't make sense to update if we've already deleted
        } else if (existingAction.type === 'create') {
          actionMap.set(key, {
            ...existingAction,
            bubble: { ...existingAction.bubble, ...action.to },
          });
        } else if (existingAction.type === 'update') {
          actionMap.set(key, {
            ...existingAction,
            to: { ...existingAction.to, ...action.to },
          });
        }
      }
    }
  }
  return Array.from(actionMap.values());
};

const persist = async () => {
  // Get a snapshot of the current actions to persist
  // and simplify them to minimize database updates
  const awaitingPersist = useBubbleStore.getState().awaitingPersist;
  const simplifiedPersist = simplifyAwaitingPersist(awaitingPersist);

  try {
    for (const action of simplifiedPersist) {
      await persistAction(action); // Assume this throws an error if persistence fails
    }
    // Only clear the persisted actions if all succeed
    useBubbleStore.setState((state) => {
      // Calculate the remaining actions assuming toPersist were at the start of the array
      const newAwaitingPersist = state.awaitingPersist.slice(
        awaitingPersist.length
      );
      return {
        awaitingPersist: newAwaitingPersist,
      };
    });
  } catch (error) {
    toast.error(`Failed to persist changes to database`, {
      description:
        error &&
        typeof error === 'object' &&
        'message' in error &&
        typeof error.message === 'string'
          ? error.message
          : 'Unknown error.',
    });
    throw error;
  }
};

type bubbleStore = {
  bubblesList: Tables<'bubbles'>[]; // list of bubbles sorted by their index in the page
  bubblesMap: Record<string, Tables<'bubbles'>>; // map of bubbles indexed by their ids
  selectedBubble?: Tables<'bubbles'>;
  undoStack: Action[];
  redoStack: Action[];
  awaitingPersist: PersistableAction[]; // actions to be persisted
};

const useBubbleStore = create<bubbleStore>(() => ({
  bubblesList: [],
  bubblesMap: {},
  selectedBubble: undefined,
  undoStack: [],
  redoStack: [],
  awaitingPersist: [],
}));

export const bubbleStoreActions = {
  deleteBubble: (bubble: Tables<'bubbles'>) =>
    doAction(deleteBubbleAction(bubble), true),
  selectBubble: (bubble: Tables<'bubbles'> | undefined): Promise<void> =>
    doAction(selectBubbleAction(bubble), false),
  createBubble: (
    bubble?: Tables<'bubbles'>,
    location?: CreateLocation | number
  ): void => {
    if (!bubble) return;
    doAction(createBubbleAction(bubble, location), true);
  },
  updateBubble: (bubble?: Tables<'bubbles'>): void => {
    if (!bubble) return;
    doAction(updateBubbleAction(bubble), true);
  },
  setBubbles: (
    bubbles: Tables<'bubbles'>[],
    comicId: string,
    // persist parameter is here so we can not keep track of progress
    // or persist when initially loading the bubbles from the database
    persist: boolean
  ): void => {
    doAction(setBubblesAction(bubbles, comicId, persist), persist);
  },
  undo: undoAction,
  redo: redoAction,
  persist: persist,
};

export default useBubbleStore;

import { supabase } from '@/lib/supabaseClient';
import { useQuery } from '@tanstack/react-query';

export const useAllComicsInSeries = (seriesId?: string) => {
  const {
    data: comics,
    isLoading: comicsLoading,
    error: comicsFetchError,
    ...rest
  } = useQuery({
    queryKey: ['comics', { seriesId }],
    queryFn: async () => {
      return await supabase
        .from('comics')
        .select()
        .eq('series_id', seriesId!)
        .order('chapter', { ascending: true })
        .throwOnError()
        .then((val) => val.data);
    },
    enabled: !!seriesId,
  });

  return { comics, comicsLoading, comicsFetchError, ...rest };
};

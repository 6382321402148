import { cn } from '@/lib/utils';
import langPanelLogoNoText from '@/assets/LangPanelNoText.svg';

const Logo = ({ className }: { className?: string }) => {
  return (
    <div className={cn('flex flex-1 items-center gap-3', className)}>
      <img
        src={langPanelLogoNoText}
        alt="LangPanel Logo"
        className="flex-1 h-[33px]"
      />
      <h2 className="font-medium text-slate-600 text-xl">LangPanel</h2>
    </div>
  );
};

export const LogoNoText = ({ className }: { className?: string }) => {
  return (
    <div className="flex flex-1">
      <img
        src={langPanelLogoNoText}
        alt="LangPanel Logo"
        className={cn('flex-1 h-[33px]', className)}
      />
    </div>
  );
};

export default Logo;

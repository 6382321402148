import { create } from 'zustand';

type ComicUploadStore = {
  userUploadedImages: File[];
  setUserUploadedImages: (files: File[]) => void;
};

const comicUploadStore = create<ComicUploadStore>((set) => ({
  userUploadedImages: [],
  setUserUploadedImages: (files: File[]) => set({ userUploadedImages: files }),
}));

export default comicUploadStore;

import { supabase } from '@/lib/supabaseClient';
import { useForm } from 'react-hook-form';
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Form,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import googleLogo from '@/assets/dashboard/icons8-google.svg';
import { FloatingLogo } from './404';

const SignUp = () => {
  const formSchema = z.object({
    email: z.string().min(3).max(100),
    password: z.string().min(8).max(256),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const handleSignUp = async (values: z.infer<typeof formSchema>) => {
    const { error } = await supabase.auth.signUp({
      email: values.email,
      password: values.password,
    });

    if (error) {
      alert(error.message);
    } else {
      alert('Check your email for your confirmation link!');
    }
  };

  const googleSignUp = async () => {
    await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: `${import.meta.env.BASE_URL}/oauth-redirect`,
      },
    });
  };

  return (
    <div className="px-4 lg:px-0 h-dvh flex flex-col lg:flex-row justify-center items-center text-slate-700">
      <FloatingLogo />
      <div className="grid grid-cols-1 lg:grid-cols-2 w-full max-w-[450px] lg:max-w-none lg:basis-[1000px] mx-4 lg:mx-0 z-10">
        <div className="hidden lg:flex flex-col justify-between">
          <div className="mt-6 flex flex-col gap-2 lg:mr-24">
            <h1 className="text-4xl font-medium leading-normal">
              Enter Into a World of Comics
            </h1>
            <span className="text-slate-400 mt-3 leading-relaxed">
              Sign up today and gain access to our advanced AI models for comic
              translation
            </span>
          </div>
          <div className="ml-1 mb-1 text-slate-500 hidden lg:block">
            Step 1 of 3
          </div>
        </div>
        <div className="px-6 py-12 lg:p-12 rounded-md flex flex-col bg-slate-50 shadow-md">
          <h1 className="text-2xl font-medium flex justify-center">
            Create Your Account
          </h1>
          <Button
            variant={'outline'}
            className="relative mt-10 text-slate-600 bg-white border-slate-200 hover:border-slate-300"
            onClick={googleSignUp}
          >
            <img
              src={googleLogo}
              alt="Google Logo"
              className="absolute left-2 translate-y-[1px] h-6 w-6"
            />
            Continue with Google
          </Button>
          <div className="flex items-center justify-center mt-6 text-slate-400 text-sm">
            <div className="flex-grow border-t border-slate-300"></div>
            <span className="mx-4">or</span>
            <div className="flex-grow border-t border-slate-300"></div>
          </div>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleSignUp)}
              className="flex flex-col mt-4"
            >
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="">
                    <FormControl>
                      <Input
                        type="email"
                        placeholder="Email"
                        autoComplete="username"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              ></FormField>
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem className="mt-4">
                    <FormControl>
                      <Input
                        type="password"
                        placeholder="Password"
                        autoComplete="new-password"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              ></FormField>
              <Button type="submit" className="mt-6" disabled={true}>
                LangPanel is still in early access
              </Button>
            </form>
          </Form>

          <div className="mt-10 flex justify-center text-sm text-slate-400 gap-2">
            Already have an account?
            <a
              href="/sign-in"
              className="underline text-slate-500 hover:text-slate-700 transition-all text-right"
            >
              Log in.
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;

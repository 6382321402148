import { useBetaSignupForm } from '@/hooks/messages/useBetaSignupForm';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { ReactNode } from 'react';
import { cn } from '@/lib/utils';

export const BetaSignupForm = () => {
  const { form, handleSubmit } = useBetaSignupForm();

  return (
    <form
      onSubmit={form.handleSubmit(handleSubmit)}
      className="flex-1 flex max-w-[550px] items-stretch relative rounded-md mx-0"
    >
      <Input
        type="email"
        placeholder="Enter your email"
        className="h-10 sm:h-14 rounded-md font-medium text-base bg-white"
        required
        {...form.register('email')}
      />
      <Button
        type="submit"
        className="absolute right-0 min-h-10 sm:min-h-14 text-sm tracking-tight sm:tracking-normal sm:text-md rounded-none rounded-r-md px-4 sm:px-6 border-none"
      >
        Request Access
      </Button>
    </form>
  );
};

export const LandingContainer = ({
  children,
  className,
}: {
  children?: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'mx-6 lg:mx-8 w-full sm:max-w-screen-sm lg:max-w-[1100px] flex-1',
        className
      )}
    >
      {children}
    </div>
  );
};

export const LandingNavContainer = ({
  children,
  className,
}: {
  children?: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'mx-4 lg:mx-8 max-w-screen-sm lg:max-w-[1300px] flex-1',
        className
      )}
    >
      {children}
    </div>
  );
};
